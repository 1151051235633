import { memo } from 'react';
import { AlignType, MoveType } from '@appsumo/dorado-react';
import { Arrow, AutoPlay } from '@egjs/flicking-plugins';
import { ViewportSlot } from '@egjs/react-flicking';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';

import type { DealSkuCardType } from '~/components/sku/types';
import { SpotlightCard } from '~/components/sku/SpotlightCard';
import SumoFlicking from '~/components/smartcollections/components/SumoFlicking';

/**
 * This component gets around the issue with flicking not being circular when not enough cards are present.
 * Clone elements to fill the flicking panel so the overall width > ~1400px.
 * This will only apply to collections with 4-6 deals.
 *
 * Sum of panel sizes - panel size >= viewport size
 * On full width desktop, in testing, viewport is ~1700px, current panel size is ~300px
 *
 * https://naver.github.io/egjs-flicking/Options#circular
 */
const plugins = [
  new Arrow({
    prevElSelector: '.flicking-prev',
    nextElSelector: '.flicking-next',
  }),
  new AutoPlay({ duration: 5000, direction: 'NEXT', stopOnHover: true }),
];

const SpotlightFlicking = memo(
  ({
    deals,
    priority = false,
  }: Readonly<{
    deals: DealSkuCardType[];
    priority: boolean;
  }>) => {
    const clonedDeals =
      deals.length < 7 && deals.length > 3 ? [...deals, ...deals] : deals;

    return (
      <div className="w-full pl-4">
        <SumoFlicking
          flickingProps={{
            plugins,
            align: AlignType.PREV,
            circular: true,
            moveType: MoveType.FREE_SCROLL,
          }}
        >
          {clonedDeals.map((deal, index) => (
            <SpotlightCard
              key={`${deal.id}_${index}`}
              deal={deal}
              priority={priority && index === 0}
              hideBanner
            />
          ))}
          <ViewportSlot>
            <div className="mt-4 flex gap-x-2 max-sm:hidden">
              <button className="flicking-prev h-8 w-8 rounded-full bg-dollar text-black-pearl">
                <span className="sr-only">Previous</span>
                <FontAwesomeIcon icon={faArrowLeft} />
              </button>
              <button className="flicking-next h-8 w-8 rounded-full bg-dollar text-black-pearl">
                <span className="sr-only">Next</span>
                <FontAwesomeIcon icon={faArrowRight} />
              </button>
            </div>
          </ViewportSlot>
        </SumoFlicking>
      </div>
    );
  },
);

SpotlightFlicking.displayName = 'SpotlightFlicking';
export default SpotlightFlicking;
